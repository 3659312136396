<template>
  <div>
    <siteHeader />

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <h3 class="mb-0">Account Details</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <form>
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-lg-4">
                        <base-input
                          label="Name"
                          placeholder="Name"
                          :value="model.full_name"
                          disabled
                        />
                      </div>
                      <div class="col-lg-4">
                        <base-input label="Member Group" disabled>
                          <el-select
                            style="width: 100%"
                            v-model="model.member_group"
                            placeholder="Member Group"
                            disabled
                          >
                            <el-option
                              v-for="option in memberData"
                              :value="option.id"
                              :label="option.members_group_name"
                              :key="option.id"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </div>

                      <div class="col-lg-4">
                        <base-input label="Membership" disabled>
                          <el-select
                            style="width: 100%"
                            v-model="model.membership"
                            placeholder="Membership"
                            disabled
                          >
                            <el-option
                              v-for="option in membershipData"
                              :value="option.id"
                              :label="option.membership_name"
                              :key="option.id"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4">
                        <base-input label="Subscriptions" disabled>
                          <el-select
                            style="width: 100%"
                            multiple
                            v-model="model.subscriptions"
                            placeholder="Subscriptions"
                            disabled
                          >
                            <el-option
                              v-for="option in subscriptionData"
                              :value="option.id"
                              :label="option.subscription_name"
                              :key="option.id"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </div>
                      <div class="col-lg-4">
                        <base-input
                          label="Account Balance"
                          placeholder=""
                          :value="model.balance"
                          disabled
                        />
                      </div>
                      <div class="col-lg-4">
                        <base-input
                          label="Prepaid Balance"
                          placeholder=""
                          :value="model.prepaid"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />

    <div class="content">
      <div class="container-fluid mt--5">
        <div>
          <card
            class="no-border-card"
            body-classes="pb-1"
            footer-classes="pb-2"
          >
            <div v-if="isEmailSent">
              <base-alert type="success" dismissible>
                <span class="alert-inner--text"> Email Sent Successfully</span>
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
            </div>
            <div v-if="isEmailNotSent">
              <base-alert type="danger" dismissible>
                <span class="alert-inner--text"> Error While Sending Mail</span>
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
            </div>
            <div v-if="isLoading">
              <base-alert type="danger" dismissible>
                <span class="alert-inner--text">
                  Record Deleted Successfully</span
                >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
            </div>

            <div class="col text-left">
              <base-button
                type="danger"
                size="sm"
                @click="$router.push('/account/add_charge')"
                >Add Charge</base-button
              >

              <base-button type="success" size="sm" @click="onPayment()"
                >Payment</base-button
              >
              <base-button type="primary" size="sm" @click="sendEmail()"
                >Send Email</base-button
              >
              <base-button type="primary" size="sm" @click="print()"
                >Print</base-button
              >
            </div>

            <template v-slot:footer>
              <div
                class="
                  col-12
                  d-flex
                  justify-content-center justify-content-sm-between
                  flex-wrap
                "
              >
                <div class="">
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                  </p>
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </template>

            <tabs fill class="flex-column flex-md-row">
              <card shadow>
                <tab-pane icon="ni ni-credit-card" title="Charges">
                  <div class="description">
                    <div>
                      <div class="row">
                        <div class="col-lg-3">
                          <base-input label="From Date">
                            <flat-picker
                              @on-open="focus"
                              @on-close="blur"
                              class="form-control datepicker"
                              v-model="search.fromdate"
                              @change="onStatusChange($event)"
                            >
                            </flat-picker>
                          </base-input>
                        </div>
                        <div class="col-lg-3">
                          <base-input label="To Date">
                            <flat-picker
                              @on-open="focus"
                              @on-close="blur"
                              class="form-control datepicker"
                              v-model="search.todate"
                              @change="onStatusChange($event)"
                            >
                            </flat-picker>
                          </base-input>
                          <!-- <div class="error mb-2" v-if="v$.model.ceasedate.$error">
                      Field is required.
                    </div> -->
                        </div>
                        <div class="col-lg-3">
                          <base-input label="Status">
                            <el-select
                              style="width: 100%"
                              v-model="search.status"
                              placeholder=""
                              :value="search.status"
                              filterable
                              @change="onStatusChange($event)"
                            >
                              <el-option value="paid" label="Paid"></el-option>
                              <el-option value="unpaid" label="Un-Paid">
                              </el-option>
                            </el-select>
                          </base-input>
                        </div>

                        <div class="col-lg-3">
                          <div
                            class="search-wrapper panel-heading col-sm-12"
                            style="padding-top: 30px"
                          >
                            <el-input
                              type="search"
                              clearable
                              prefix-icon="el-icon-search"
                              placeholder="Search records"
                              v-model="searchQuery"
                              aria-controls="datatables"
                            >
                            </el-input>
                          </div>
                        </div>
                      </div>
                      <br />

                      <el-table
                        :data="queriedData"
                        row-key="id"
                        header-row-class-name="thead-light"
                        @row-dblclick="dbSelected"
                      >
                        <el-table-column
                          v-for="column in tableColumns"
                          :key="column.label"
                          v-bind="column"
                          sortable
                        >
                        </el-table-column>

                        <el-table-column
                          min-width="120px"
                          align="right"
                          label="Actions"
                          sortable
                        >
                          <template v-slot:default="props">
                            <div class="d-flex">
                              <i
                                class="fas fa-edit"
                                @click="onEdit(props.row)"
                              ></i>
                              &nbsp;
                              <i
                                class="fas fa-trash-alt"
                                @click="showconfirm(props.row)"
                              ></i>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </tab-pane>
                <tab-pane icon="ni ni-book-bookmark mr-2" title="Payments">
                  <div class="description">
                    <div>
                      <div class="row">
                        <div class="col-lg-3">
                          <base-input label="From Date">
                            <flat-picker
                              @on-open="focus"
                              @on-close="blur"
                              class="form-control datepicker"
                              v-model="search.fromdate"
                              @change="onStatusChange($event)"
                            >
                            </flat-picker>
                          </base-input>
                        </div>
                        <div class="col-lg-3">
                          <base-input label="To Date">
                            <flat-picker
                              @on-open="focus"
                              @on-close="blur"
                              class="form-control datepicker"
                              v-model="search.todate"
                              @change="onStatusChange($event)"
                            >
                            </flat-picker>
                          </base-input>
                          <!-- <div class="error mb-2" v-if="v$.model.ceasedate.$error">
                      Field is required.
                    </div> -->
                        </div>
                        <div class="col-lg-3" style="padding-top: 30px"></div>

                        <div class="col-lg-3">
                          <div
                            class="search-wrapper panel-heading col-sm-12"
                            style="padding-top: 30px"
                          >
                            <el-input
                              type="search"
                              clearable
                              prefix-icon="el-icon-search"
                              placeholder="Search records"
                              v-model="searchQuery"
                              aria-controls="datatables"
                            >
                            </el-input>
                          </div>
                        </div>
                      </div>
                      <br />

                      <el-table
                        :data="queriedDataPayment"
                        row-key="id"
                        header-row-class-name="thead-light"
                      >
                        <el-table-column
                          min-width="100px"
                          align="right"
                          label="Member"
                          sortable
                        >
                          <template v-slot:default="props">
                            <div class="d-flex">
                              <div v-for="item in MemberData" :key="item.id">
                                <span v-if="item.id === props.row.member_name">
                                  {{ (props.row.full_name = item.full_name) }}
                                </span>
                              </div>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          v-for="column in tableColumnsPayment"
                          :key="column.label"
                          v-bind="column"
                          sortable
                        >
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </tab-pane>
              </card>
            </tabs>
          </card>
        </div>
      </div>

      <modal v-model:show="showEditModal" modal-classes="modal-secondary">
        <div style="text-align: center">
          <h3>Are you sure you want to delete this item?</h3>

          <base-button type="danger" @click="ondelete">Yes</base-button>
          <base-button type="success" @click="showEditModal = false"
            >No</base-button
          >
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import siteHeader from "../../global/header.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const addchargeRepository = RepositoryFactory.get("addcharge");
const membergroupRepository = RepositoryFactory.get("membergroup");
const memberShipRepository = RepositoryFactory.get("membership");
const subscriptionRepository = RepositoryFactory.get("subscription");
const paymentRepository = RepositoryFactory.get("payment");
const memberRepository = RepositoryFactory.get("member");

import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
export default {
  name: "Account",
  components: {
    flatPicker,
    siteHeader,
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      showEditModal: false,
      paymentData: [],
      isEmailSent: false,
      isEmailNotSent: false,
      membershipData: [],
      subscriptionData: [],
      chargesdatalist: [],
      memberData: [],
      search: {},
      searchQuery: "",
      isLoading: false,
      tableData: [],
      model: {
        subscriptions: [],
      },

      model1: {},
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },

      propsToSearch: [
        "charge_type",
        "amount",
        "paid_amount",
        "created_date",
        "desciption",
        "balance",
      ],

      propsToSearchPayment: ["date", "amount", "paid", "name"],

      tableColumns: [
        {
          prop: "charge_type",
          label: "Type",
          minWidth: 100,
        },
        {
          prop: "desciption",
          label: "Description",
          minWidth: 300,
        },
        {
          prop: "amount",
          label: "Amount($)",
          minWidth: 150,
        },
        {
          prop: "paid_amount",
          label: "Paid($)",
          minWidth: 120,
        },
        {
          prop: "balance",
          label: "Balance($)",
          minWidth: 120,
        },
        {
          prop: "created_date",
          label: "Date",
          minWidth: 120,
        },
      ],

      tableColumnsPayment: [
        {
          prop: "date",
          label: "Date",
          minWidth: 100,
        },
        {
          prop: "amount",
          label: "Amount($)",
          minWidth: 100,
        },
        {
          prop: "paid",
          label: "Paid",
          minWidth: 100,
        },
        {
          prop: "note",
          label: "Notes",
          minWidth: 100,
        },
      ],
      fuseSearch: null,
      searchedData: [],
    };
  },
  methods: {
    onStatusChange() {
      this.queriedData;
      this.queriedDataPayment;
    },
    onSearch() {},

    showconfirm(row) {
      // this.model1 = row;
      this.model1.id = row.memb_char_id;
      this.model1.charge_type = row.charge_type;
      this.model1.charge_id = row.charge_id;
      this.model1.desciption = row.desciption;
      this.amount = row.amount;
      this.model1.is_delete = "Yes";
      this.showEditModal = true;
    },

    print() {
      window.open(
        "https://shulsoftware.adass.com.au/api/index.php/AccountAddCharge/print/" +
          this.model.id,
        "_blank"
      );
    },
    async sendEmail() {
      let response = await addchargeRepository.sendEmail(this.model.id);

      if (response.data.sent == true) {
        this.isEmailSent = true;
        setTimeout(() => {
          this.isEmailSent = false;
        }, 1000);
      }
      if (response.data.sent == false) {
        this.isEmailNotSent = true;
        setTimeout(() => {
          this.isEmailNotSent = false;
        }, 1000);
      }
    },
    onPayment() {
      let data = localStorage.getItem("accountmember");
      let data1 = JSON.parse(data);
      this.$router.push({
        name: "createpayment",
        params: { data: data1.id },
      });
    },
    dbSelected(row) {
      row.edit = 1;
      localStorage.setItem("chargeaccout", JSON.stringify(row));
      this.$router.push("add_charge");
    },
    onEdit(row) {
      row.edit = 1;
      localStorage.setItem("chargeaccout", JSON.stringify(row));
      this.$router.push("add_charge");
    },
    async getChargesList(model) {
      if (model.id) {
        this.tableData = await addchargeRepository.getda(model.id);
        this.chargesdatalist = this.tableData.data;
        for (let index = 0; index < this.chargesdatalist.length; index++) {
          const element = this.chargesdatalist[index];
          element.balance = element.balance ? element.balance : 0;
          this.chargesdatalist[index].balance = "$" + element.balance;
          element.amount = element.amount ? element.amount : 0;
          this.chargesdatalist[index].amount = "$" + element.amount;
          element.paid_amount = element.paid_amount ? element.paid_amount : 0;
          this.chargesdatalist[index].paid_amount = "$" + element.paid_amount;
        }
      }
    },
    async ondelete() {
      // this.model1.id = row.memb_char_id;
      // this.model1.charge_type = row.charge_type;
      // this.model1.charge_id = row.charge_id;
      // this.model1.desciption = row.desciption;
      // this.amount = row.amount;
      this.model1.is_delete = "Yes";
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model1, "data", []);
      let response = await addchargeRepository.delete(data);
      await this.getChargesList(this.model);
      if (response.data.status == 101) {
        this.isLoading = true;
        this.showEditModal = false;
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }
    },
    async getMemberGroupList() {
      this.tableData = await membergroupRepository.getMember();
      this.memberData = this.tableData.data;
    },
    async getMembershipList() {
      this.tableData = await memberShipRepository.getMembership();
      this.membershipData = this.tableData.data;
    },
    async getSubscriptionList() {
      this.tableData = await subscriptionRepository.getSubscription();
      this.subscriptionData = this.tableData.data;
    },
    async getList(model) {
      this.tableData = await paymentRepository.getPayment(model.id);
      this.paymentData = this.tableData.data;
      for (let index = 0; index < this.paymentData.length; index++) {
        const element = this.paymentData[index];
        element.amount = element.amount ? element.amount : 0;
        this.paymentData[index].amount = "$" + element.amount;
      }
    },
  },
  async mounted() {
    await this.getMemberGroupList();
    await this.getMembershipList();
    await this.getSubscriptionList();
    this.tableData = await memberRepository.getMember();
    this.MemberData = this.tableData.data;
    localStorage.removeItem("chargeaccout");
    let data = localStorage.getItem("accountmember");
    let data1 = JSON.parse(data);
    if (data1) {
      if (data1.edit == 1) {
        this.model = data1;
        const model_data = await memberRepository.getMemberByid(this.model.id);
        this.model = data1 = model_data.data[0];
        this.model.subscriptions = JSON.parse(data1.subscriptions);
        this.getChargesList(this.model);
        this.getList(this.model);
      }
    }
  },
  computed: {
    pagedData() {
      return this.chargesdatalist.slice(this.from, this.to);
    },
    pagedDataPayment() {
      return this.paymentData.slice(this.frompay, this.topay);
    },

    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (
        !this.searchQuery &&
        !this.search.status &&
        !this.search.fromdate &&
        !this.search.todate
      ) {
        return this.pagedData;
      }
      let result = this.chargesdatalist;
      if (this.searchQuery) {
        result = result.filter((row) => {
          let isIncluded = false;
          for (let key of this.propsToSearch) {
            if (row[key]) {
              let rowValue = row[key].toString();
              if (
                rowValue.includes &&
                rowValue.toLowerCase().includes(this.searchQuery)
              ) {
                isIncluded = true;
              }
            }
          }

          return isIncluded;
        });
      }

      if (this.search.status) {
        result = result.filter((row) => {
          let isIncluded = false;
          console.log(row);
          if (row["balance"] == 0 && this.search.status == "paid") {
            isIncluded = true;
          }
          if (row["balance"] != 0 && this.search.status == "unpaid") {
            isIncluded = true;
          }
          return isIncluded;
        });
      }

      if (this.search.fromdate) {
        result = result.filter((row) => {
          let isIncluded = false;
          let parts = row["created_date"].split("/");
          const date1 = new Date(parts[2], parts[1] - 1, parts[0]);
          const date2 = new Date(this.search.fromdate);
          if (date1 >= date2) {
            isIncluded = true;
          }
          return isIncluded;
        });
      }

      if (this.search.todate) {
        result = result.filter((row) => {
          let isIncluded = false;
          let parts = row["created_date"].split("/");
          const date1 = new Date(parts[2], parts[1] - 1, parts[0]);
          const date2 = new Date(this.search.todate);
          if (date1 <= date2) {
            isIncluded = true;
          }
          return isIncluded;
        });
      }

      return result.slice(this.from, this.to);
    },

    queriedDataPayment() {
      if (!this.searchQuery && !this.search.fromdate && !this.search.todate) {
        return this.pagedDataPayment;
      }
      let result = this.paymentData;

      if (this.searchQuery) {
        result = result.filter((row) => {
          let isIncluded = false;
          for (let key of this.propsToSearch) {
            if (row[key]) {
              let rowValue = row[key].toString();
              if (
                rowValue.includes &&
                rowValue.toLowerCase().includes(this.searchQuery)
              ) {
                isIncluded = true;
              }
            }
          }

          return isIncluded;
        });
      }

      if (this.search.fromdate) {
        result = result.filter((row) => {
          let isIncluded = false;

          let parts = row["date"].split("/");
          const date1 = new Date(parts[2], parts[1] - 1, parts[0]);
          const date2 = new Date(this.search.fromdate);

          if (date1 >= date2) {
            isIncluded = true;
          }

          return isIncluded;
        });
      }

      if (this.search.todate) {
        result = result.filter((row) => {
          let isIncluded = false;

          let parts = row["date"].split("/");
          const date1 = new Date(parts[2], parts[1] - 1, parts[0]);

          const date2 = new Date(this.search.todate);
          if (date1 <= date2) {
            isIncluded = true;
          }
          return isIncluded;
        });
      }

      return result.slice(this.frompay, this.topay);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.chargesdatalist.length;
    },

    topay() {
      let highBound = this.frompay + this.pagination.perPage;
      if (this.totalPay < highBound) {
        highBound = this.totalPay;
      }
      return highBound;
    },
    frompay() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    totalPay() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.paymentData.length;
    },
  },
};
</script>

<style></style>
